<script setup lang="ts">
import type { IPagination } from '@/common/models/pagination';
import AppTable from '@/ui-kit/components/AppTable.vue';
import { computed, defineEmits, inject, ref } from 'vue';
import { DateFormat } from '@/ui-kit/utils/dateFormat.util';
import BaseTooltip from '@/ui-kit/components/BaseTooltip.vue';
import type {
  IColumnHeader,
  IDefaultParams,
  ITableAllRowsSelectEvent,
  ITableRowClickEvent,
  ITableRowSelectEvent,
} from '@/ui-kit/models/table.model';
import { useI18n } from '@/ui-kit/composables/useI18n';
import { RuLocalesEnum } from '@/ui-kit/enums/ruLocales.enum';
import type { AppTableSelectService } from '@/common/services/appTableSelect.service';
import type { IProjectItem } from '@/views/srm/projects/api/models/responses/projectItem.model';
import { PROJECTS_TABLE_COLUMNS } from '@/views/srm/projects/api/constants/projectsTableColumns.const';
import { StatusTranslationsEnum } from '@/common/enums/stageSupplierParticipationStatuses.enum';
import { useRouter } from 'vue-router';
import { useProfileStore } from '@/stores/profile.store';
import { PermissionsEnum } from '@/common/enums/permissions.enum';

const props = defineProps<{
  projects: IPagination<IProjectItem>;
  columns?: Array<IColumnHeader> | false;
  selectable?: boolean;
  isSecurity?: boolean;
  isShowPopupActions?: boolean;
}>();

defineEmits<{
  (e: 'update:pagination', value: IDefaultParams);
  (e: 'reload'): void;
}>();

const { t } = useI18n();
const page = ref<number>(props.projects?.page);
const router = useRouter();
const profileStore = useProfileStore();

const tableSelectService = inject<AppTableSelectService<IProjectItem>>('tableSelectService');

const tableColumns = computed<Array<IColumnHeader>>(() => {
  if (props.columns) {
    return props.columns;
  }
  return PROJECTS_TABLE_COLUMNS
});

function onGoToProject(id: number): void {
  if (profileStore.hasPermission([PermissionsEnum.SupplierProjectView])) {
    router.push(`/projects/view/${id}`);
  }
}
</script>

<template>
  <div
    v-if="projects"
    class="projects-table"
  >
    <Suspense>
      <AppTable
        :columns="tableColumns"
        :data="projects?.items || []"
        :is-pagination-shown="projects?.items.length > 20"
        :selectable-mode="selectable"
        :pagination="{
          page: page,
          pageSize: projects.size,
          dataCount: projects.total,
          size: projects.size
        }"
        :is-select-all-partial="tableSelectService.isSelectAllSemiSelected"
        :selectable-control-accept-disabled="!tableSelectService.selectedCount"
        :all-rows-selected="tableSelectService.isAllSelected"
        is-selectable-page-size
        :selectable-control="selectable"
        selectable-control-secondary-accept-text="Отклонить выбранные"
        selectable-control-accept-text="Согласовать выбранные"
        :reset-rows-after-confirm-selectable-control="false"
        selectable-control-cancel-disabled
        selectable-control-cancel-text=""
        :page-size-list="[20, 50, 100]"
        :is-row-selected="(event: IProjectItem) => tableSelectService.isRowSelected(event)"
        is-show-more-btn-hidden
        @update:pagination="$emit('update:pagination', $event)"
        @selectable-control:cancel="() => tableSelectService.deselectAll()"
        @on-all-rows-select="(event: ITableAllRowsSelectEvent) => tableSelectService.selectAll(event)"
        @on-row-select="(event: ITableRowSelectEvent) => tableSelectService.selectItem(event as ITableRowSelectEvent<IProjectItem>)"
        @on-row-click="(event: ITableRowClickEvent<IProjectItem>) => onGoToProject(event.row.projectId)"
      >
        <template #tableSelectableControlPreText>
          <div class="pre-text">
            {{ t(RuLocalesEnum.Selected, tableSelectService.selectedCount) }}
            <span>{{ tableSelectService.selectedCount }}</span>
            {{ t(RuLocalesEnum.SelectedProjects, tableSelectService.selectedCount) }}
          </div>
        </template>

        <template #number="{ row }: { row: IProjectItem }">
          <span>{{ row['number'] || '&mdash;' }}</span>
          <div class="project-number-be">
            БЕ
            <BaseTooltip
              hover
              arrow
              offset-distance="8"
              offset-skid="3"
              placement="top-start"
              class=" mm-tooltip mm-tooltip--base project-number-be--popper"
            >
              <span class="project-number-be--name">{{ row.businessEntity.shortName }}</span>
              <template #content>
                {{ row.businessEntity.name || row.businessEntity.shortName }}
              </template>
            </BaseTooltip>
          </div>
        </template>

        <template #project="{ row }">
          <div class="projects-table--project">
            <p class="projects-table--project__name">
              {{ row['name'] || '&mdash;' }}
            </p>
          </div>
        </template>

        <template #stage="{ row }">
          <div class="stage-info">
            <p v-if="row['stageNumber']">{{ row['stageNumber'] }}.</p>
            <div class="text-with-subtext">
              <p>{{ row['stage']?.name || '&mdash;' }}</p>
              <p>{{ row['isOpen'] ? 'Открытый' : 'Закрытый' }}</p>
            </div>
          </div>
        </template>

        <template #stageStatus="{ row }">
          <p class="stage-status">{{ row['stageStatus']?.name }}</p>
        </template>

        <template #editTime="{ row }">
          <div class="suggestions-time">
            <div class="date-wrapper">
              <div class="text-with-subtext">
                <p>{{ DateFormat.default(row['updated']) || '&mdash;' }}</p>
                <p>{{ DateFormat.timeWithoutSeconds(row['updated']) || '&mdash;' }}</p>
              </div>
            </div>
          </div>
        </template>

        <template #participationStatus="{ row }">
          <p>{{ StatusTranslationsEnum[row['stageSupplierParticipationStatusCode']] }}</p>
        </template>

        <template #requestsTs="{ row }">
          <div class="suggestions-time">
            <div class="date-wrapper">
              <div class="text-with-subtext">
                <p>{{ DateFormat.default(row['offerStartTs']) || '&mdash;' }}</p>
                <p>{{ DateFormat.timeWithoutSeconds(row['offerStartTs']) || '&mdash;' }}</p>
              </div>
              <span v-if="row['offerEndTs']">&mdash;</span>
              <div class="text-with-subtext">
                <p>{{ DateFormat.default(row['offerEndTs']) || '&mdash;' }}</p>
                <p>{{ DateFormat.timeWithoutSeconds(row['offerEndTs']) || '&mdash;' }}</p>
              </div>
            </div>
          </div>
        </template>
      </AppTable>
    </Suspense>
  </div>
</template>

<style scoped lang="scss">
@import '@styles/base/common/variables';

:global(.project-number-be--popper .popper) {
  max-width: 163px !important;
}

:global(.projects-popup .popup-actions__content__item) {
  padding-bottom: 16px;
}

:global(.dp__input_icon) {
  left: 10px !important;
}

.projects-table {
  margin-left: -32px;
  width: calc(100% + 64px);

  &-project-method {
    font-size: 12px;
    line-height: 16px;
    color: $light-green;
    margin-bottom: 0;
    font-weight: 400;
  }

  :deep(.mm-table:not(.mm-table--with-selectable-control)) {
    width: calc(100% - 2px);
    margin-left: 1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    .mm-table__table.mm-table--theme-default {
      .mm-table__head {
        .mm-table__header-row {
          th {
            .mm-table__header-row-content {
              padding: 0;
            }

            border-bottom: 1px solid $gray-200;

            &:nth-child(1),
            &:nth-child(5) {
              width: 140px;
              padding-left: 32px;
            }

            &:nth-child(2) {
              width: 280px;
            }

            &:nth-child(3) {
              width: 280px;
            }

            &:nth-child(4) {
              width: 240px;
            }

            &:nth-child(5) {
              width: 260px;
            }

            &:nth-child(6) {
              width: 160px;
            }

            &:nth-child(7) {
              width: 284px;
            }

            &:last-child {
              padding-right: 32px;
            }
          }
        }
      }

      .mm-table__body {
        .mm-table__row {
          td {
            padding: 20px 16px 20px 0;

            &:nth-child(1),
            &:nth-child(5) {
              padding-left: 32px;
            }

            &:last-child {
              padding-right: 32px;
            }
          }

          &:hover .projects-table--project__name {
            color: $link;
          }
        }
      }
    }
  }

  :deep(.mm-table--with-selectable-control) {
    .mm-table {
      width: 100%;
      margin-left: 0;
    }

    > div:nth-child(2) {
      margin-left: -1px;
      width: calc(100% + 2px);
    }

    .checkbox__input {
      margin: 0;
    }

    .checkbox {
      width: fit-content;
    }

    .mm-pagination-page-size {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .table-selectable-control {
      margin-left: -1px;
      width: calc(100% + 2px);
    }

    .table-selectable-control__wrapper {
      border-bottom: 1px solid $table-border-c;
      border-left: 1px solid $table-border-c;
      border-right: 1px solid $table-border-c;
    }

    .mm-pagination {
      border-left: 1px solid $table-border-c;
      border-right: 1px solid $table-border-c;
    }

    .mm-table__head {
      .mm-table__header-row {
        th {
          &:nth-child(1) {
            width: unset !important;

            > div {
              width: fit-content;
            }

            .checkbox {
              margin-left: 0;
            }
          }

          &:last-child {
            padding-right: 32px;
          }

          &:nth-child(5) {
            padding-left: 0 !important;
            border-left: 0 !important;
          }

          &:nth-child(6) {
            padding-left: 32px;
            border-left: 1px solid $expanded-table-divider-color;
          }
        }
      }
    }

    .mm-table__body {
      .mm-table__row {
        td {
          &:nth-child(1) {
            padding-right: 20px !important;
            width: 56px !important;
          }

          &:nth-child(5) {
            padding-left: 0 !important;
            border-left: 0 !important;
          }

          &:nth-child(6) {
            padding-left: 32px !important;
            border-left: 1px solid $expanded-table-divider-color;
          }
        }
      }
    }
  }

  :deep(.table-selectable-control__wrapper.mm-container.mm-container--without-max-width) {
    .table-selectable-control__save-btn {
      &.secondary {
        color: $link;
        padding: 10px 40px;

        &:disabled {
          color: $text-disabled;
        }
      }

      &.btn-primary {
        padding: 10px 28px;
      }
    }
  }

  .pre-text {
    font-size: 14px;
    line-height: 20px;

    span {
      font-weight: 500;
    }
  }

  .date-wrapper {
    display: flex;
    width: 182px;
    gap: 12px;
    justify-content: space-between;

    div {
      width: 74px;
    }
  }

  :deep(.created-time-tooltip .popper) {
    max-width: 214px !important;
    width: 100%;
    padding: 8px 12px;
  }

  :deep(.mm-pagination-page-size) {
    border-bottom: 1px solid $expanded-table-divider-color;
    border-right: 1px solid $expanded-table-divider-color;
    border-left: 1px solid $expanded-table-divider-color;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .text-with-subtext {
    p {
      &:first-child {
        color: $text-black;
        margin-bottom: 8px;
      }

      &:last-child {
        font-size: 12px;
        line-height: 16px;
        color: $light-green;
        margin-bottom: 0;
      }
    }
  }

  .stage-status {
    color: $text-black;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    padding: 6px 12px;
    border: 1px solid $dark-gray;
    border-radius: 4px;
    width: fit-content;
  }

  .project-number-be {
    margin-top: 8px;
    color: $light-green;
    font-size: 12px;
    line-height: 16px;

    &--name {
      color: $text-dark-green;
    }
  }

  .stage-info {
    display: flex;
    gap: 4px;
    margin-top: 7px;
  }

  .tooltip-content {
    &--block {
      p {
        margin-bottom: 0;

        &:first-child {
          font-size: 12px;
          line-height: 16px;
          color: $light-green;
        }

        &:last-child {
          font-size: 14px;
          line-height: 20px;
          color: $text-white;
        }
      }

      &:first-child {
        border-bottom: 1px solid $text-light-green;
        padding-bottom: 8.5px;
        margin-bottom: 8.5px;
      }
    }
  }

  .suggestions-time {
    display: flex;
    justify-content: space-between;
  }

  .projects-table--project {
    &__name {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    &__owners {
      &--owner {
        margin-bottom: 0;

        &:not(:last-child) {
          margin-bottom: 4px;
          border-right: 1px solid $dark-gray;
        }

        width: fit-content;
        font-size: 12px;
        line-height: 16px;
        padding-right: 8px;

        span {
          color: $light-green;
        }
      }
    }
  }
}
</style>
