import type { IColumnHeader } from '@/ui-kit/models/table.model';

export const PROJECTS_TABLE_COLUMNS: Array<IColumnHeader> = [
  { title: 'Номер', field: 'number' },
  { title: 'Проект', field: 'project' },
  { title: 'Этап', field: 'stage' },
  { title: 'Статус этапа', field: 'stageStatus' },
  { title: 'Срок сбора предложений', field: 'requestsTs' },
  { title: 'Дата изменения', field: 'editTime' },
  { title: 'Статус участия', field: 'participationStatus' },
];
