export enum ProjectsFiltersEnum {
  NOMENCLATURE_GROUPS = 'NOMENCLATURE_GROUPS',
  STAGE_TYPES = 'STAGE_TYPES',
  STAGE_STATUSES = 'STAGE_STATUSES',
  BUSINESS_ENTITIES = 'BUSINESS_ENTITIES',
  STAGE_SUPPLIER_PARTICIPATION_STATUSES = 'STAGE_SUPPLIER_PARTICIPATION_STATUSES'
}

export enum ProjectsFiltersRequestEnum {
  StartOfCollecting = 'startOfCollecting',
  NomenclatureGroups = 'nomenclatureGroups',
  StageTypes = 'stages',
  StageStatuses = 'stageStatuses',
  isOpen = 'isOpen',
  OfferEnd = 'offerEnd',
  BusinessEntities = 'businessEntityIds',
  STAGE_SUPPLIER_PARTICIPATION_STATUSES = 'stage_supplier_participation_status_codes'
}
